import $ from 'jquery';
import 'jquery-lazy';
import '@fancyapps/fancybox';

import picturefill from 'picturefill';
import navigation from './components/layout/navigation';
import instafeed from './components/layout/instafeed';
import lazyload from './components/layout/lazyload';
import accordion from './components/layout/accordion';
import video from './components/layout/video';
import images from './components/layout/images';
import mapMarkers from './components/layout/mapMarkers';
import validation from './components/forms/validation';
import formEvents from './components/forms/formEvents';
import RecaptchaForm from './components/forms/RecaptchaForm';
import floatingLabels from './utils/floatingLabels';
import gtmEvents from './components/tracking/gtmEvents';
import CookieNotification from './components/cookies/CookieNotification';

import Contact from './pages/Contact';

require('./libs/lazyPicture');
require('./libs/plugins/objectfit');
require('waypoints/lib/noframework.waypoints.js');
require('./libs/plugins/cycle2.min');
require('./utils/nativeConsole');

// use with fancybox, cycle2, etc
require('jquery-touchswipe/jquery.touchSwipe');

window.jQuery = $;
window.picturefill = picturefill();

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
window.cookieNotification = new CookieNotification();

lazyload();
navigation();
instafeed();
accordion();
video();
images();
mapMarkers();
gtmEvents();

// Forms
validation();
floatingLabels();
formEvents();

$.each($('.recaptcha-form'), (i, form) => {
  const contactForm = new RecaptchaForm(form);
  window.submitRecaptchaFormContact = () => {
    contactForm.submitCallback();
  };
});

const backLink = document.querySelector('[data-back-link]');
if (backLink) {
  backLink.addEventListener('click', (e) => {
    e.preventDefault();
    window.history.back();
  });
}

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}
